class EventManager {
    serviceName: string = 'EventManager';
    map: {[key: string]: any} = {};

    assign(event: string, fn: Function) {
        if (!this.map.hasOwnProperty(event)) {
            this.map[event] = [];
        }
        this.map[event].push(fn);
        return this.unassign.bind(this, event, fn);
    }

    subscribe(events: Array<string> | string, fn: Function) : () => void {
        events = Array.isArray(events) ? events : [events];
        const unsubscriptions = events.map((ev) => {
            return this.assign(ev, fn);
        });
        return this.unsubscribe.bind(this, unsubscriptions);
    }

    unassign(event: string, fn: Function) {
        let index = this.map[event].indexOf(fn);
        if (index > -1) {
            this.map[event].splice(index, 1);
        }
    }

    unsubscribe(unsubscriptions: Array<Function>) {
        unsubscriptions.map(unassign => unassign() );
    }

    notify(event: string, ...extra: any[]) {
        if (!this.map.hasOwnProperty(event) || this.map[event].length === 0) {
            return;
        }
        const args = Array.prototype.slice.call(arguments, 1);
        args.push(event);
        this.map[event].map((fn: Function) => fn.apply(this, args) );
    }
}

export default new EventManager();
