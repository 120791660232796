export function PCPSmart({ addBy = false }: {addBy?: boolean}) {
    return (
        <div className="pcp-smart">
            <strong className="pcp-smart-pcp">PCP</strong><span className="pcp-smart-smart">Smart</span>
            { addBy ? (
                <span className="pcp-smart-by-line">
                    <span className="pcp-smart-by">by</span> <strong className="pcp-smart-jcinfo">JCinfo</strong>
                </span>
            ) : null }
        </div>
    );
}
