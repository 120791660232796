import { Home } from "./Home";
import { Login } from "./auth/Login";
import { PasswordRecovery } from "./auth/PasswordRecovery";
import { PasswordReset } from "./auth/PasswordReset";
import { DefaultLayout } from "./layout/DefaultLayout";
import { Error404 } from "./layout/Error404";

import { CustomersList } from 'views/customer/List';
import { QueueList } from 'views/queue/List';

export const Views = {
    DefaultLayout,
    Home,

    Login,
    PasswordRecovery,
    PasswordReset,

    CustomersList,
    QueueList,

    Error404,
};
