import Axios from 'axios';
import { Config } from 'configs';

const api = Axios.create({
    withCredentials: true,
    baseURL: `${Config.app.api}/admin/v1`,
    headers: {
        'X-Requested-With': 'XMlHttpRequest'
    }
});

export default api;
