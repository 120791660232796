import { ChangeEvent, ReactNode } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function ListTop({
  q,
  newResourcePath,
  newResourceText,
  searchText,
  icon = 'post_add',
  custom = null,
  onSearch,
  toggleFilters,
}: {
  q: string;
  newResourcePath?: string;
  newResourceText?: string;
  searchText?: string;
  icon?: string;
  custom?: ReactNode;
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  toggleFilters?: () => void;
}) {
  return (
    <div className="d-flex justify-content-between">
      <div className="list-search-side d-flex align-items-start gap-2 mb-20p">
        <InputGroup className="list-serach-input">
          <FormControl
            onChange={onSearch}
            value={q}
            className="border-end-0"
            placeholder={searchText}
            aria-label={searchText}
          />
          <span className="btn bg-white border-color-input d-flex align-items-center border-start-0 cursor-default">
            <span className="material-icons material-icons-outlined fs-5">
              search
            </span>
          </span>
        </InputGroup>
        {toggleFilters ? (
          <Button
            onClick={toggleFilters}
            variant="white"
            className="text-nowrap"
          >
            <span>Filtrar</span>{' '}
            <span className="material-icons material-icons-outlined fs-5 align-middle">
              arrow_drop_down
            </span>
          </Button>
        ) : null}
      </div>
      <div className="list-actions-side mb-20p">
        {newResourcePath ? (
          <Link className="btn btn-primary" to={newResourcePath}>
            <span className="material-icons material-icons-outlined fs-5 align-middle me-2">
              {icon}
            </span>
            <span className="align-middle">{newResourceText}</span>
          </Link>
        ) : null}
        {custom}
      </div>
    </div>
  );
}
