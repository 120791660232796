import { Model, Rest } from "./Rest";

export interface QueueModel extends Model {
  id: string,
  queue: string,
  created_at: string,
}

export class QueueService extends Rest {
    resourceable_as: string = 'App\\Models\\Queue';

    getDefaultModel(merge?: Record<string, any>): Omit<QueueModel, 'id'>  {
        return {
            queue: '',
            created_at: '',
            ...merge
        };
    }
}

export default new QueueService('QueueService', '/queues');
