import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import NotificationService from 'services/NotificationService';
import { RestInterface } from 'services/Rest';
import { statuses } from 'types/General';

export function useDelete<M>(
    service: RestInterface,
    afterDelete?: (res: AxiosResponse) => void
): {
    deletingStatus: statuses;
    deletingModel: M | false;
    setDeletingModel: (model: M | false) => void;
    confirmDelete: () => void;
} {
    const [deletingStatus, setDeletingStatus] = useState<statuses>('idle');
    const [deletingModel, setDeletingModel] = useState<M | false>(false);

    function confirmDelete() {
        setDeletingStatus('pending');
        service
            // @ts-ignore
            .delete(deletingModel.id || 0)
            .then((res: AxiosResponse) => {
                setDeletingStatus('success');
                if (afterDelete) {
                    afterDelete(res);
                }
            })
            .catch((error: AxiosError) => {
                setDeletingStatus('error');
                if (!error.response) {
                    return;
                }
                NotificationService.danger(
                    'Ops, não conseguimos realizar esta ação.',
                    'Erro de validação'
                );
            });
    }

    return {
        deletingModel,
        deletingStatus,
        setDeletingModel,
        confirmDelete,
    };
}
