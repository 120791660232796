import { ChangeEvent, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { StateLinkable } from 'types/Form';
// import Password from 'antd/lib/input/Password';
// import { StateLinkable } from '../Interfaces';

export function InputPassword({link, error, suffix, prefix, placeholder, inputProps = {} } :
    {
        link: StateLinkable,
        error?: string | null,
        suffix?: ReactNode,
        prefix?: ReactNode,
        placeholder: string,
        inputProps?: Record<string, any>,
    }
) {
    let errorElement: null | JSX.Element = null;
    // let itemClass: string = 'ant-row ant-form-item';
    // const labelClass = required ? 'ant-form-item-required' : '';
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        link.onChange(e);
    }

    if (error) {
        errorElement = (
            <Form.Control.Feedback type="invalid">
                { error }
            </Form.Control.Feedback>
        );
    }

    return (
        <div className="mb-15p">
            <Form.Control
                type="password"
                placeholder={ placeholder }
                onChange={ onChange }
                value={ link.value }
                name={ link.name }
                isInvalid={ !!error }
                { ...inputProps }
            />
            { errorElement }
        </div>
        // <div className={ itemClass }>
        //     <div className="ant-col ant-form-item-label">
        //         <label className={ labelClass } title={ label }>{ label }</label>
        //     </div>
        //     <div className="ant-col ant-form-item-control">
        //         <div className="ant-form-item-control-input">
        //             <div className="ant-form-item-control-input-content">
        //                 <Password
        //                     name={ link.name }
        //                     onChange={ onChange }
        //                     value={ link.value }
        //                     suffix={ suffix }
        //                     prefix={ prefix }
        //                     autoComplete={ autoComplete }
        //                 />
        //             </div>
        //         </div>
        //         { errorElement }
        //     </div>
        // </div>
    );
}
