import { ChangeEvent, ReactNode } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { StateLinkable } from 'types/Form';

export function InputText({ link, error = null, help = null, suffix, prefix, placeholder = '', inputProps = {}, className = '', mask, filter } :
    {
        link: StateLinkable,
        error?: string | null,
        help?: string | null,
        suffix?: ReactNode,
        prefix?: JSX.Element,
        placeholder?: string,
        // autoComplete?: 'on' | 'off' | 'email' | 'username' | 'new-password' | 'current-password',
        inputProps?: Record<string, any>,
        className?: string,
        mask?: (value: any) => any,
        filter?: (value: any) => any,
    }
) {
    let errorElement: ReactNode = null;
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        if (mask) {
            e.target.value = mask(e.target.value);
        }
        if (filter) {
            e.target.value = filter(e.target.value);
        }
        link.onChange(e);
    }

    let inputElement = (
        <Form.Control
            className={ className }
            type="text"
            placeholder={ placeholder }
            onChange={ onChange }
            value={ mask ? mask(link.value) : link.value }
            name={ link.name }
            isInvalid={ !!error }
            { ...inputProps }
        />
    );

    if (prefix) {
        inputElement = (
            <InputGroup>
                <InputGroup.Text>{ prefix }</InputGroup.Text>
                { inputElement }
            </InputGroup>
        );
    }

    if (error) {
        errorElement = (
            <Form.Control.Feedback type="invalid">
                { error }
            </Form.Control.Feedback>
        );
    }

    return (
        <div className="mb-15p">
            { inputElement }
            { help ? <small className="fs-12p text-secondary">{ help }</small> : null }
            { errorElement }
        </div>
    );
}
