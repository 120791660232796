// import api from "./api";
// import EventManager from './EventManager';
// import { Record<string, any> } from "../types/Routing";

import api from "./api";
import { AxiosResponse, AxiosError } from "axios";

export interface Model {
    [key: string]: any
}

export interface RestInterface {
    find: (id: number | string, qs?: Record<string, any>) => Promise<any>,
    get: (qs: Record<string, any>) => Promise<any>,
    store: (data: FormData | Model) => Promise<any>,
    update: (data: FormData | Model, partial?: boolean) => Promise<any>,
    save: (data: FormData | Model) => Promise<any>,
    delete: (id: number | string) => Promise<any>,
    // getFormRelations: (action?: string, querystring?: Record<string, any>) => Promise<any>,
    // export: (qs?: Record<string, any>) => Promise<any>,
    // getName: () => string
}

export class Rest implements RestInterface {
    protected serviceName: string;
    protected resoruceRoute: string;
    protected resourceable_as: string = '';

    constructor(serviceName: string, resoruceRoute: string) {
        this.serviceName = serviceName;
        this.resoruceRoute = resoruceRoute;
    }

    find (id: number | string, qs?: Record<string, any>): Promise<any> {
        return new Promise((resolve, reject) => {
            api.get(`${this.resoruceRoute}/${id}`, {
                params: qs
            })
            .then((response: AxiosResponse) => {
                // EventManager.notify(`${this.serviceName}.find`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                // EventManager.notify(`${this.serviceName}.find.error`, error);
                reject(error);
            });
        });
    }

    get (qs: any): Promise<any> {
        return new Promise((resolve, reject) => {
            api.get(this.resoruceRoute, {
                params: qs
            })
            .then((response: AxiosResponse) => {
                // EventManager.notify(`${this.serviceName}.get`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                // EventManager.notify(`${this.serviceName}.get.error`, error);
                reject(error);
            });
        });
    }

    store (data: FormData | Model): Promise<any> {
        return new Promise((resolve, reject) => {
            api.post(this.resoruceRoute, data)
            .then((response: AxiosResponse) => {
                // EventManager.notify(`${this.serviceName}.store`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                // EventManager.notify(`${this.serviceName}.store.error`, error);
                reject(error);
            });
        });
    }

    update (data: FormData | Model, partial: boolean = false): Promise<any> {
        let id: any;
        const _method = partial ? 'PATCH' : 'PUT';
        if (data instanceof FormData) {
            data.append('_method', _method);
            id = data.get('id');
        } else {
            data._method = _method;
            id = data.id;
        }

        return new Promise((resolve, reject) => {
            api.post(`${this.resoruceRoute}/${id}`, data)
            .then((response: AxiosResponse) => {
                // EventManager.notify(`${this.serviceName}.update`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                // EventManager.notify(`${this.serviceName}.update.error`, error);
                reject(error);
            });
        });
    }

    save (data: FormData | Model): Promise<any> {
        let id = data instanceof FormData ? data.get('id') : data.id;
        if (!id) {
            return this.store(data);
        }
        else {
            return this.update(data);
        }
    }

    delete(id: string | number): Promise<any> {
        return new Promise((resolve, reject) => {
            api.delete(`${this.resoruceRoute}/${id}`, {
                data: {_method: 'DELETE'},
            })
            .then((response: AxiosResponse) => {
                // EventManager.notify(`${this.serviceName}.delete`, response);
                resolve(response);
            })
            .catch((error: AxiosError) => {
                // EventManager.notify(`${this.serviceName}.delete.error`, error);
                reject(error);
            });
        });
    }

//     getFormRelations(action?: string, querystring: Record<string, any> = {}): Promise<any> {
//         return new Promise((resolve, reject) => {
//             api.get(`${this.resoruceRoute}/get-form-relations`, {
//                 params: { ...querystring, action }
//             })
//             .then((response: AxiosResponse) => {
//                 EventManager.notify(`${this.serviceName}.getFormRelations`, response);
//                 resolve(response);
//             })
//             .catch((error: AxiosError) => {
//                 EventManager.notify(`${this.serviceName}.getFormRelations.error`, error);
//                 reject(error);
//             });
//         });
//     }

//     export (qs?: Record<string, any>): Promise<any> {
//         return new Promise((resolve, reject) => {
//             api.get('/resource-export', {
//                 params: {...qs, model: this.resourceable_as.split('\\').pop()}
//             })
//             .then((response: AxiosResponse) => {
//                 EventManager.notify(`${this.serviceName}.export`, response);
//                 resolve(response);
//             })
//             .catch((error: AxiosError) => {
//                 EventManager.notify(`${this.serviceName}.export.error`, error);
//                 reject(error);
//             });
//         });
//     }

//     getName(): string {
//         return this.serviceName;
//     }

//     getResourceableAs(): string {
//         return this.resourceable_as;
//     }

    getDefaultModel(merge?: Record<string, any>): Model {
        return {
            ...merge
        };
    }
}
