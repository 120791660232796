// import { notification } from 'antd';

import { ReactNode } from "react";
import EventManager from "./EventManager";

type NotificatonPositions = 'top-start' | 'top-center' | 'top-end' | 'middle-start' | 'middle-center' | 'middle-end' | 'bottom-start' | 'bottom-center' | 'bottom-end';

export type ToastNotification = {
    title: ReactNode,
    body: JSX.Element | string,
    variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
    position: NotificatonPositions,
};

export class NotificationService {
    notifications: Array<ToastNotification> = [];
    defaultDuation: number = 4500;
    defaultPosition: NotificatonPositions = 'top-end';
    EVENTS = {
        update: 'NotificationService.update'
    };

    notify(notification: ToastNotification, timeout: number | null = null) {
        this.notifications.push(notification);
        let tmo = timeout || this.defaultDuation;
        EventManager.notify(this.EVENTS.update, this.notifications);

        if (tmo) {
            window.setTimeout(() => {
                this.remove(notification);
            }, tmo);
        }
    }

    remove(notification: ToastNotification) {
        let index = this.notifications.indexOf(notification);
        this.notifications.splice(index, 1);
        EventManager.notify(this.EVENTS.update, this.notifications);
    }

    success(body: JSX.Element | string, title?: ReactNode, timeout?: number, position?: NotificatonPositions) {
        this.notify({
            title,
            body,
            position: position || this.defaultPosition,
            variant: 'success',
        }, timeout);
    }

    danger(body: JSX.Element | string, title?: ReactNode, timeout?: number, position?: NotificatonPositions) {
        this.notify({
            title,
            body,
            position: position || this.defaultPosition,
            variant: 'danger',
        }, timeout);
    }

    warning(body: JSX.Element | string, title?: ReactNode, timeout?: number, position?: NotificatonPositions) {
        this.notify({
            title,
            body,
            position: position || this.defaultPosition,
            variant: 'warning',
        }, timeout);
    }

    info(body: JSX.Element | string, title?: ReactNode, timeout?: number, position?: NotificatonPositions) {
        this.notify({
            title,
            body,
            position: position || this.defaultPosition,
            variant: 'info',
        }, timeout);
    }
}

export default new NotificationService();
