import { PCPSmart } from "assets/PCPSmart";
import { Form } from "components/Form/Form";
import { InputText } from "components/Form/InputText";
// import { Route } from "helpers/Route";
import { ChangeEvent, ReactNode, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routes } from "routing/routes";
import AuthService from "services/AuthService";

export function PasswordRecovery() {
    let ErrorElement: ReactNode = null;
    const [data, setData] = useState({
        email: '',
        return_url: AuthService.getResetPasswordUrl(),
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [recoveryRequested, setRecoveryRequested] = useState(false);

    function change(field: string, e: ChangeEvent<HTMLInputElement>) {
        setData({ ...data, [field]: e.target.value });
    }

    function getError(field: string) {
        if (errors[field] !== undefined) {
            for (const prop in errors[field]) {
                return errors[field][prop];
            }
        }
        return null;
    }

    function onSubmit(e: SubmitEvent) {
        e.preventDefault();
        setLoading(true);
        AuthService.passwordRecovery(data).then((data: any) => {
            setErrors({});
            setRecoveryRequested(true);
        }).catch((err) => {
            if (err.response) {
                switch (err.response.status) {
                    case 422:
                        setErrors(err.response.data.error_data);
                        break;
                    default:
                        break;
                }
            }
            setLoading(false);
        });
    }

    if (errors.non_field) {
        ErrorElement = (
            <Alert className="mt-3" variant="danger">{ errors.non_field }</Alert>
        );
    }

    function renderForm() {
        if (recoveryRequested) {
            return (
                <div className="text-center">
                    <Alert variant="success"><strong>Enviado!</strong> Verifique seu e-mail e siga as instruções para criar uma nova senha.</Alert>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <Link className="text-secondary small" to={ routes.login.path }>Voltar para tela de login</Link>
                        <span className="material-icons material-icons-outlined fs-5 ms-2">login</span>
                    </div>
                </div>
            );
        }
        return (
            <>
            <Form className="auth-form" onSubmit={ onSubmit } loading={ loading }>
                <div className="mb-2">
                    <label htmlFor="password-recovery-email">Recuperação de senha:</label>
                </div>
                <InputText
                    placeholder="Digite o seu e-mail usado no cadastro"
                    link={ {onChange: change.bind(null, 'email'), value: data.email} }
                    error={ getError('email') }
                    inputProps={ {
                        id: 'password-recovery-email',
                        autoComplete: 'email'
                    } }
                />
                <div className="d-flex justify-content-between align-items-center">
                    <Button type="submit">Recuperar</Button>
                    <div className="d-flex align-items-center">
                        <Link className="text-secondary small" to={ routes.login.path }>Voltar para tela de login</Link>
                        <span className="material-icons material-icons-outlined fs-5 ms-2">login</span>
                    </div>
                </div>
            </Form>
            { ErrorElement }
            </>
        );
    }

    return (
        <div className="auth-layout auth-layout-password-recovery">
            <div className="auth-form-wrapper">
                <div className="auth-logo-row">
                    <PCPSmart addBy />
                </div>
                <div className="auth-form-row">
                    { renderForm() }
                </div>
                <div className="auth-label-row">
                    <div className="bg-info bg-opacity-15">Painel administrador</div>
                </div>
            </div>
        </div>
    );
}
