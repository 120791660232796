import { DynamicTable, DynamicTableColumn } from "components/DynamicTable";
import { Paginator } from "components/Paginator";
import { useList } from "hooks/useList";
import { useSearchParams } from "react-router-dom";
import QueueService, { QueueModel } from "services/QueueService";

export function QueueList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    onPaginate,
    onSort,
  } = useList<QueueModel>(QueueService, searchParams, setSearchParams);

  const columns: DynamicTableColumn<QueueModel>[] = [
    {
      label: 'ID',
      field: 'id',
      style: { width: '60px', textAlign: 'center' },
      thStyle: {
        width: '60px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      sortable: true,
    },
    {
      label: 'Tipo',
      field: 'queue',
      // render: (customer) => customer.name,
      sortable: true,
    },
    {
      label: 'Data de criação',
      field: 'created_at',
      style: { width: '260px' },
      render: (resource) => (
        <span className="text-nowrap">
          {new Date(resource.created_at).toLocaleDateString('pt-BR', {
            year: 'numeric', month: 'long', day: 'numeric',
          })}
        </span>
      ),
      sortable: true,
    },
  ];


  return (
    <div className="layout-wrapper layout-wrapper-list">
      {/* <div className="mb-4">
        <ListTop
          q={searchParams.get('q') || ''}
          onSearch={onSearch}
          searchText="Buscar cliente"
          toggleFilters={toggleFilters.bind(null, !isFiltersVisible)}
          custom={
            <Button
              onClick={setFormItem.bind(
                null,
                Queueervice.getDefaultModel()
              )}
              variant="primary"
            >
              <span className="material-icons material-icons-outlined fs-5 align-middle me-2">
                person_add
              </span>
              <span className="align-middle">Novo cliente</span>
            </Button>
          }
        />
      </div> */}

      {/* <div className="pb-3">
        <QueueFilters
          isFiltersVisible={isFiltersVisible}
          linkFilter={linkFilter}
          // searchParams={searchParams}
        />
      </div> */}

      <div className="mb-2">
        <DynamicTable
          loading={status === 'pending'}
          getRowKey={(queue) => queue.id}
          columns={columns}
          collection={collection}
          searchParams={searchParams}
          onSort={onSort}
        />
      </div>

      <Paginator
        currentPage={pagination.currentPage}
        offset={pagination.offset}
        total={pagination.total}
        onPaginate={onPaginate}
      />
    </div>
  );
}
