// import { DepartamentModel } from './DepartamentService';
import { Model } from './Rest';

// export interface AbilityModel extends Model{
//     id?: string,
//     name?: string,
//     title?: string,
//     entity_id?: number,
//     entity_type?: string,
//     only_owned?: boolean,
//     // options?: string,
//     // scope?: string,
//     resource?: 'App\\Models\\Departament' |
//             'App\\Models\\User' |
//             'App\\Models\\Contact' |
//             'App\\Models\\BankAccount' |
//             'App\\Models\\Customer' |
//             'App\\Models\\Supplier' |
//             'App\\Models\\Service',
//     updated_at?: string,
//     created_at?: string,
// }

export interface UserModel extends Model{
    id?: string,
    name: string,
    email: string,
    ind_admin: boolean,
    // active?: boolean,
    // departament_id?: number,
    // departament?: DepartamentModel,
    // abilities: AbilityModel[],
    updated_at: string,
    created_at: string,
}

export class UserService {
    resourceable_as: string = 'App\\Models\\User';

    getDefaultModel(merge?: Record<string, any>): UserModel {
        return {
            name: '',
            email: '',
            ind_admin: false,
            updated_at: '',
            created_at: '',
            ...merge
        };
    }
}

// export default new UserService('UserService', '/users');
export default new UserService();
