export default function MaskCNPJ(str: any) {
    if (typeof str !== 'string') {
        return str;
    }
    return str.replace(/\D/g, '')
        .slice(0, 14)
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/,'$1.$2')
        .replace(/(\d{3})(\d)/,'$1/$2')
        .replace(/(\d{4})(\d)/,'$1-$2');
}
