import{ ChangeEvent, ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { FilterLinkable } from 'types/List';

export function TextFilter({ placeholder, link, suffix, prefix, mask, filter } :
    {
        placeholder: string,
        link: FilterLinkable,
        suffix?: ReactNode,
        prefix?: ReactNode,
        mask?: (value: any) => any,
        filter?: (value: any) => any,
    }
) {
    function onChange(e: ChangeEvent<HTMLInputElement>) {
        if (mask) {
            e.target.value = mask(e.target.value);
        }
        if (filter) {
            e.target.value = filter(e.target.value);
        }
        link.onChange(e);
    }

    return (
        <div className="mb-20p">
            <Form.Control
                type="text"
                placeholder={ placeholder }
                onChange={ onChange }
                value={ mask ? mask(link.value) : link.value }
                name={ link.name }
                // { ...inputProps }
            />
        </div>
    );
}
