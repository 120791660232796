import { Pagination } from "react-bootstrap";

export type PaginatorProps = {
    currentPage: number,
    total: number,
    offset?: number,
    showingPages?: number,
    onPaginate: (page: number) => void,
};

export function Paginator({ currentPage, total, offset = 15, showingPages = 3, onPaginate }: PaginatorProps) {
    if (total <= offset) {
        return null;
    }

    const lastPage = Math.ceil(total / offset);
    const hasPreviousReticents = currentPage > showingPages;
    const hasNextReticents = lastPage > (currentPage + showingPages);
    const lastShowingPages = hasNextReticents ? (currentPage + showingPages) : lastPage;
    const firstPageDisabled = currentPage === 1;
    const lastPageDisabled = currentPage === lastPage;

    let i = hasPreviousReticents ? (currentPage - showingPages) : 0;
    const pages = [];

    for (i; i < lastShowingPages; i += 1) {
        pages.push(i + 1);
    }

    function paginate(page: number) {
        if (page !== currentPage) {
            onPaginate(page);
        }
    }

    return (
        <Pagination className="mb-0">
            <Pagination.First
                disabled={ firstPageDisabled }
                aria-disabled={ firstPageDisabled }
                onClick={ paginate.bind(null, 1) }
            />

            <Pagination.Prev
                disabled={ firstPageDisabled }
                aria-disabled={ firstPageDisabled }
                onClick={ paginate.bind(null, currentPage - 1) }
            />
            
            { hasPreviousReticents ? <Pagination.Ellipsis /> : null }

            { pages.map((page, index) => {
                return (
                    <Pagination.Item key={ index } active={ page === currentPage }  onClick={ onPaginate.bind(null, page) }>
                        { page }
                    </Pagination.Item>
                );
            }) }

            { hasNextReticents ? <Pagination.Ellipsis /> : null }

            <Pagination.Next
                disabled={ lastPageDisabled }
                aria-disabled={ lastPageDisabled }
                onClick={ paginate.bind(null, currentPage + 1) }
            />

            <Pagination.Last
                disabled={ lastPageDisabled }
                aria-disabled={ lastPageDisabled }
                onClick={ paginate.bind(null, lastPage) }
            />
        </Pagination>
    );
}
