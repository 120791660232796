import api from './api';
import { AxiosError, AxiosResponse } from 'axios';
import { routes } from 'routing/routes';
import { Model } from './Rest';
import { Config } from 'configs';
import EventManager from './EventManager';
import UserService, { UserModel } from './UserService';

export class AuthService {
    private user: UserModel = UserService.getDefaultModel();
    protected serviceName: string = 'AuthService';
    private defaultRedirect: string = '/';
    private intentedRoute: null | string = null;
    // private isUserAuthenticated: boolean = !!this.getUser();
    private isUserAuthenticated: boolean = false;
    public EVENTS = {
        userSet: `${this.serviceName}.user.set`
    };

    authenticate(data: {email: string, password: string}) {
        return new Promise((resolve, reject) => {
            api.get(`${Config.app.api}/v1/csrf-cookie`).then((res: AxiosResponse) => {
                api.post('/auth/authenticate', data).then((response) => {
                    this.isUserAuthenticated = true;
                    this.setUser(response.data);
                    // EM.notify(`${this.serviceName}.authenticate`, response, this.intentedRoute);
                    resolve({
                        response: response,
                        redirect: this.intentedRoute || this.defaultRedirect
                    });
                    this.intentedRoute = null;
                }).catch((error: AxiosError) => {
                    reject(error);
                });
            }).catch((err: AxiosError) => {
                reject(err);
            });
        });
    }

    passwordRecovery(data: {email?: string, return_url?: string}) {
        return new Promise((resolve, reject) => {
            api.post('/auth/password-recovery', data).then((res) => {
                resolve(res);
            }).catch((error: AxiosError) => {
                reject(error);
            });
        });
    }

    passwordReset(data: {email: string, token: string, new_password: string, new_password_confirmation: string}) {
        return new Promise((resolve, reject) => {
            api.post('/auth/password-reset', data).then((res) => {
                resolve(res);
            }).catch((error: AxiosError) => {
                reject(error);
            });
        });
    }

    me() {
        return new Promise((resolve, reject) => {
            api.get('/me').then((res: AxiosResponse) => {
                this.setUser(res.data.user);
                resolve(res);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            api.post('/auth/logout').then((res) => {
                resolve(res);
                this.forgetUser();
            }).catch((error) => {
                reject(error);
            });
        });
    }

    isAuthenticated() {
        return this.isUserAuthenticated;
    }

    setIntentedRoute(route: string) {
        if (route !== routes.login.path) {
            this.intentedRoute = route || null;
        }
    }

    setUser(user: UserModel) {
        this.user = user;
        // localStorage.setItem(
        //     Config.auth.user_index || '',
        //     encode(JSON.stringify(user))
        // );
        EventManager.notify(this.EVENTS.userSet, user);
    }

    getUser() {
        return this.user;
        // const userData = localStorage.getItem(Config.auth.user_index);
        // if (!userData) {
        //     return {};
        // }
        // return JSON.parse(
        //     decode(userData || '')
        // );
    }

    forgetUser() {
        this.isUserAuthenticated = false;
        // localStorage.removeItem(Config.auth.user_index);
        // EM.notify(`${this.serviceName}.user.forget`, user);
    }

    getResetPasswordUrl() {
        return `${window.location.origin}${routes.passwordReset.path}`;
    }
}

export default new AuthService();
