import { AxiosError, AxiosResponse } from "axios";
import GetFlattened from "helpers/GetFlattened";
import SetFlattened from "helpers/SetFlattened";
import { useEffect, useState } from "react";
import NotificationService from "services/NotificationService";
import { RestInterface } from "services/Rest";
import { ErrorsType, StateLinkable } from "types/Form";
import { statuses } from "types/General";

type useFormOptions = {
    afterSave?: (res: AxiosResponse) => void,
    id?: string | number
};

export function useForm<M>(
    service: RestInterface,
    initialState: M,
    options: useFormOptions = {},
) : {
    status: statuses,
    state: M,
    link: ((field: string) => StateLinkable),
    getError: (field: string) => string | null,
    submit: (data: FormData | M) => void,
} {
    const { id } = options;
    const [status, setStatus] = useState<statuses>('idle');
    const [state, setState]  = useState<M>(initialState);
    const [errors, setErrors]  = useState<ErrorsType>({});

    function getError(field: string) {
        if (errors[field] !== undefined) {
            for (const prop in errors[field]) {
                return errors[field][prop];
            }
        }
        return null;
    }

    function changeStateField(field: string, value: any) {
        setState(
            SetFlattened(field, value, { ...state })
        );
    }

    function link(field: string) {
        return {
            name: field,
            value: GetFlattened(field, state, ''),
            onChange: (e: any) => {
                changeStateField(field, e.target.value);
            }
        };
    }

    function submit(data: M | FormData) {
        setStatus('pending');
        setErrors({});
        service.save(data)
        .then((res: AxiosResponse) => {
            setStatus('success');
            if (options?.afterSave) {
                options.afterSave(res);
            }
        })
        .catch((error: AxiosError) => {
            setStatus('error');
            if (!error.response) {
                return;
            }
            switch (error.response.status) {
                case 422:
                    NotificationService.danger('Ops, verifique todos os campos.', 'Erro de validação');
                    setErrors({hasErrors: true, ...error.response.data.error_data});
                    break;
                default:
                    break;
            }
        });
    }

    useEffect(() => {
        if (id) {
            setStatus('pending');
            service.find(id)
            .then((res: AxiosResponse) => {
                setStatus('idle');
                setState(res.data.data);
            })
            .catch((error: AxiosError) => {
                setStatus('error');
                if (!error.response) {
                    return;
                }
                switch (true) {
                    case error.response.status >= 500 && error.response.status < 600:
                        NotificationService.danger('Ops, tivemos um erro interno.', 'Erro interno');
                        break;
                    default:
                        break;
                }
            });
        }
    }, [service, id]);

    return {
        status,
        state,
        link,
        getError,
        submit,
    };
}
