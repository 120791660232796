import { AxiosError, AxiosResponse } from "axios";
// import { useNavigate } from "react-router-dom";
import api from "services/api";
import NotificationService from "services/NotificationService";
import { routes } from "./routes";
import { BrowserHistory } from "history";

export function AuthMiddleware(history: BrowserHistory) {
    const allowedRoutes = [
        routes.login.path,
        routes.passwordRecovery.path,
        routes.passwordReset.path,
    ];

    api.interceptors.response.use((response: AxiosResponse) => {
        return response;
    }, (error: AxiosError) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            switch (error.response.status) {
                case 400:
                case 401:
                    if (allowedRoutes.indexOf(window.location.pathname) < 0) {
                        history.push(routes.login.path);
                    }
                    break;
                case 403:
                    NotificationService.warning('Ops, parece que você não tem permissão para esta ação. 😕', 'Autorização negada');
                    break;
                case 500:
                    NotificationService.danger('Ops, tivemos um problema interno e já estamos trabalhando pra resolver. 😓', 'Erro no servidor');
                    break;
                default:
                    break;
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMlHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error);
        }
        // // console.log(error.config);
        return Promise.reject(error);
    });
}
