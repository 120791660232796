import { Spinner } from 'react-bootstrap';

export function LoadingWrapper({ loading, children } : {loading?: boolean, children: any}) {
    return (
        <div className="loading-wrapper">
            { loading ? <div className="loading-wrapper-element"><Spinner animation="grow" /></div> : null }
            { children }
        </div>
    );
}
