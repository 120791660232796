import { TextFilter } from 'components/Filters/TextFilter';
import MaskCNPJ from 'helpers/MaskCNPJ';
import MaskNumeric from 'helpers/MaskNumeric';
import { Col, Collapse, Row } from 'react-bootstrap';
import { FilterLinkCallable } from 'types/List';

export function CustomersFilters({
  isFiltersVisible,
  linkFilter,
}: {
  isFiltersVisible: boolean;
  linkFilter: FilterLinkCallable;
}) {
  return (
    <Collapse in={isFiltersVisible}>
      <div className="mb-3">
        <Row>
          <Col md="3">
            <TextFilter
              placeholder="Código"
              link={linkFilter('internal_code', '~')}
            />
          </Col>
          <Col md="3">
            <TextFilter
              placeholder="Administrador"
              link={linkFilter('admin.name')}
            />
          </Col>
          <Col md="3">
            <TextFilter
              placeholder="CNPJ"
              link={linkFilter('document')}
              mask={MaskCNPJ}
              filter={MaskNumeric}
            />
          </Col>
        </Row>
      </div>
    </Collapse>
  );
}
