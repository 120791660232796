import { Views } from "views";
// import { RouteItem } from "types/Routing";

export const routes = {
    home: {path: '/', title: 'Clientes', Component: Views.CustomersList},

    // orders: {path: '/encomendas', title: 'Encomendas', Component: Views.OrdersList},
    // ordersCreate: {path: '/encomendas/novo', title: 'Encomendas » Nova', Component: Views.OrdersCreate},
    // ordersEdit: {path: '/encomendas/:id/alterar', title: 'Encomendas » Alterar', Component: Views.OrdersEdit},

    // productionOrders: {path: '/ordem-de-producao', title: 'Gerar OP', Component: Views.ProductionOrderList},
    // productsCreate: {path: '/produtos/novo', title: 'Produtos » Novo', Component: Views.ProductsCreate},
    // productsEdit: {path: '/produtos/:id/alterar', title: 'Produtos » Alterar', Component: Views.ProductsEdit},

    // customers: {path: '/clientes', title: 'Clientes', Component: Views.CustomersList},
    // customersCreate: {path: '/clientes/novo', title: 'Clientes » Novo', Component: Views.CustomersCreate},
    // customersEdit: {path: '/clientes/:id/alterar', title: 'Clientes » Alterar', Component: Views.CustomersEdit},
    queue: {path: '/fila', title: 'Fila de processamento', Component: Views.QueueList},

    login: {path: '/autenticacao/login', title: 'Login', Component: Views.Login, Layout: null},
    passwordRecovery: {path: '/autenticacao/recuperar-senha', title: 'Recuperar senha', Component: Views.PasswordRecovery, Layout: null},
    passwordReset: {path: '/autenticacao/resetar-senha', title: 'Resetar senha', Component: Views.PasswordReset, Layout: null},

    _404: {path: '*', title: 'Página não encontrada', Component: Views.Error404, Layout: null},
};
