import { Form } from 'components/Form/Form';
import { InputText } from 'components/Form/InputText';
import MaskCNPJ from 'helpers/MaskCNPJ';
import MaskNumeric from 'helpers/MaskNumeric';
import { useForm } from 'hooks/useForm';
import { Fragment } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { routes } from 'routing/routes';
import CustomerService, { CustomerModel } from 'services/CustomerService';

export function CustomersForm({
    id,
    inModal = false,
    afterSave,
    onCancel,
}: {
    id?: string;
    inModal?: boolean;
    afterSave: () => void;
    onCancel: () => void;
}) {
    const { state, status, getError, link, submit } = useForm<CustomerModel>(
        CustomerService,
        CustomerService.getDefaultModel(),
        { afterSave, id }
    );

    function beforeSubmit(e: SubmitEvent) {
        e.preventDefault();
        const data = { ...state, document_type: 'CNPJ', return_url: `${window.location.origin.replace(/admin\./ig, '')}${routes.passwordReset.path}` };
        submit(data);
    }

    let FieldsWrapper: any = Fragment;
    let ButtonsWrapper: any = Fragment;

    if (inModal) {
        FieldsWrapper = Modal.Body;
        ButtonsWrapper = Modal.Footer;
    }

    return (
        <Form onSubmit={beforeSubmit} loading={status === 'pending'}>
            <FieldsWrapper className="px-0">
                <Row>
                    <Col md="6">
                        <InputText
                            placeholder={'Razão social'}
                            link={link('name')}
                            error={getError('name')}
                        />
                    </Col>
                    <Col md="3">
                        <InputText
                            placeholder="CNPJ"
                            link={link('document')}
                            error={getError('document')}
                            mask={MaskCNPJ}
                            filter={MaskNumeric}
                        />
                    </Col>
                    <Col md="3">
                        <InputText
                            placeholder="Nome fantasia"
                            link={link('trade_name')}
                            error={getError('trade_name')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="7">
                        <InputText
                            placeholder="Nome do administrador"
                            link={link('admin.name')}
                            error={getError('admin.name')}
                        />
                    </Col>
                    <Col md="5">
                        <InputText
                            placeholder="E-mail"
                            link={link('admin.email')}
                            error={getError('admin.email')}
                        />
                    </Col>
                </Row>
            </FieldsWrapper>
            <ButtonsWrapper className="px-0">
                <div className="d-flex gap-3 w-100">
                    <Button type="submit">Salvar</Button>
                    <Button onClick={onCancel} variant="white">
                        Cancelar
                    </Button>
                </div>
            </ButtonsWrapper>
        </Form>
    );
}
