import { CSSProperties, ReactNode } from "react";
import { Table } from "react-bootstrap";
import { LoadingWrapper } from "./LoadingWrapper";

export type DynamicTableColumn<M> = {
    label: ReactNode,
    field: string,
    sortable?: boolean,
    style?: CSSProperties,
    thStyle?: CSSProperties,
    render?: (item: M, i: number) => ReactNode,
}

export type DynamicTableProps<M> = {
    columns: DynamicTableColumn<M>[],
    searchParams: URLSearchParams,
    collection: any[],
    loading?: boolean,
    onSort?: (field: string) => void,
    getRowKey?: (item: any) => string | undefined,
};

export function DynamicTable<M>({ columns, searchParams, collection, loading, onSort, getRowKey }: DynamicTableProps<M>) {
    function sort(column: DynamicTableColumn<M>) {
        if (typeof onSort !== 'function') {
            return;
        }

        let sortField = searchParams.get('sort') || '',
            sortDirection = '';

        if (sortField[0] === '-') {
            sortField = sortField.slice(1);
            sortDirection = '-';
        }

        if (sortField === column.field) {
            sortDirection = (sortDirection === '-') ? '' : '-';
        } else {
            sortField = column.field;
            sortDirection = '';
        }

        onSort(`${sortDirection}${sortField}`);
    }

    function renderThead() {
        let field = searchParams.get('sort') || '';
        let direction = '';
        if (field[0] === '-') {
            field = field.slice(1);
            direction = '-';
        }

        return (
            <thead>
                <tr>
                    { columns.map((column: DynamicTableColumn<M>, i) => {
                        let icon = null;
                        let thClass = 'th pt-3';
                        let onSortClick = undefined;

                        if (column.sortable === true) {
                            onSortClick = sort.bind(null, column);
                            let iconClass = 'material-icons material-icons-outlined fs-6 text-';
                            let iconName = '';
                            thClass += ' th-sortable cursor-pointer';
                            if (column.field === field) {
                                iconClass += 'primary';
                                iconName = (direction === '-') ? 'arrow_drop_down' : 'arrow_drop_up';
                            }
                            icon = <span className={ iconClass }>{ iconName }</span>;
                        }

                        return (
                            <th onClick={ onSortClick } className={ thClass } key={ i } style={ column.thStyle }>
                                { column.label } { icon }
                            </th>
                        );
                    }) }
                </tr>
            </thead>
        );
    }

    return (
        <LoadingWrapper loading={ loading }>
            <Table className="border-top" striped hover responsive="md">
                { renderThead() }
                <tbody className="border-top">
                    { collection.map((item, i: number) => {
                        return (
                            <tr key={ getRowKey ? getRowKey(item) : i }>
                                { columns.map((column: DynamicTableColumn<M>, j) => {
                                    return (
                                        <td key={ `${column.field}-${j}` } style={ column.style }>
                                            { column.render ? column.render(item, i) : item[column.field] }
                                        </td>
                                    );
                                }) }
                            </tr>
                        );
                    }) }
                </tbody>
            </Table>
        </LoadingWrapper>
    );
}
