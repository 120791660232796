import Init from "routing/Router";
import { routes } from "routing/routes";
import 'assets/scss/app.scss';
import AuthService from "services/AuthService";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

Init(routes, () => {
    AuthService.me();
});
