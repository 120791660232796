import { CustomerUserModel } from "./CustomerUserService";
import { Model, Rest } from "./Rest";

export interface CustomerModel extends Model {
    id?: string,
    name: string,
    document_type: string,
    document: string,
    trade_name?: string,
    state_inscription?: string,
    municipal_inscription?: string,
    zip_code?: string,
    address?: string,
    complement?: string,
    number?: string,
    neighborhood?: string,
    city_id?: number,
    state_id?: number,
    updated_at: string,
    created_at: string,
    admin?: CustomerUserModel,
    users: CustomerUserModel[]
}

export class CustomerService extends Rest {
    resourceable_as: string = 'App\\Models\\Customer';

    getDefaultModel(merge?: Record<string, any>): CustomerModel  {
        return {
            name: '',
            document_type: '',
            document: '',
            updated_at: '',
            created_at: '',
            users: [],
            ...merge
        };
    }
}

export default new CustomerService('CustomerService', '/customers');
