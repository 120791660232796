import { PCPSmart } from 'assets/PCPSmart';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routes } from 'routing/routes';
import { UserModel } from 'services/UserService';

export default function Header({
  user,
  onLogout,
}: {
  user: UserModel | null;
  onLogout: () => void;
}) {
  return (
    <header className="header">
      <div className="header-content">
        <h1 className="logo">
          <Link to={routes.home.path}>
            <PCPSmart />
          </Link>
        </h1>

        <nav className="auth-nav">
          <ul className="auth-menu">
            {/* <li className="auth-menu-li">
              <Link className="auth-menu-link" to={routes.queue.path}>
                <span className="material-icons-outlined">toc</span>
              </Link>
            </li> */}
            <li className="auth-menu-li">
              <Link className="auth-menu-link" to={routes.home.path}>
                {user?.name}
                <span className="material-icons-outlined">account_circle</span>
              </Link>
            </li>
            <li className="auth-menu-li">
              <Button
                onClick={onLogout}
                variant="link"
                className="auth-menu-link"
              >
                Sair
                <span className="material-icons-outlined">logout</span>
              </Button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
