import { PCPSmart } from 'assets/PCPSmart';
import { useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { routes } from 'routing/routes';
import AuthService from 'services/AuthService';
import EventManager from 'services/EventManager';
import { UserModel } from 'services/UserService';
import Header from './Header';

export function DefaultLayout() {
  const [user, setUser] = useState<UserModel | null>(null);
  const navigation = useNavigate();

  function onLogout() {
    AuthService.logout()
      .then(() => {
        navigation(routes.login.path);
      })
      .catch(() => {});
  }

  useLayoutEffect(() => {
    function onUserSet(user: UserModel) {
      setUser(user);
    }
    const unsubs = EventManager.subscribe(
      AuthService.EVENTS.userSet,
      onUserSet
    );
    setUser(AuthService.getUser());

    return unsubs;
  }, []);

  return (
    <div className="default-layout">
      <Header user={user} onLogout={onLogout} />
      <main className="default-layout-main">
        <PCPSmart addBy />
        <div className="container-fluid default-layout-container">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
