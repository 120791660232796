import { DynamicTable, DynamicTableColumn } from 'components/DynamicTable';
import { ListTop } from 'components/Layout/ListTop';
import { Paginator } from 'components/Paginator';
import { useDelete } from 'hooks/useDelete';
import { useList } from 'hooks/useList';
import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import CustomerService, { CustomerModel } from 'services/CustomerService';
import NotificationService from 'services/NotificationService';
import { CustomersFilters } from './Filters';
import { CustomersForm } from './Form';

export function CustomersList() {
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });
  const {
    status,
    collection,
    pagination,
    isFiltersVisible,
    fetchCollection,
    onSearch,
    onPaginate,
    onSort,
    linkFilter,
    toggleFilters,
  } = useList<CustomerModel>(CustomerService, searchParams, setSearchParams);
  const { deletingModel, deletingStatus, confirmDelete, setDeletingModel } =
    useDelete<CustomerModel>(CustomerService, afterDelete);
  const [formItem, setFormItem] = useState<CustomerModel | false>(false);

  const columns: DynamicTableColumn<CustomerModel>[] = [
    {
      label: 'COD',
      field: 'id',
      style: { width: '60px', textAlign: 'center' },
      thStyle: {
        width: '60px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      sortable: true,
    },
    {
      label: 'Razão social',
      field: 'name',
      render: (customer) => customer.name,
      sortable: true,
    },
    {
      label: 'Nome Fantasia',
      field: 'trade_name',
      render: (customer) => customer.trade_name,
      sortable: true,
    },
    {
      label: 'Admin',
      field: 'users.name',
      render: (customer) => customer.admin?.name,
      sortable: false,
    },
    {
      label: 'Editar',
      field: 'edit',
      style: { width: '1px' },
      render: (customer: CustomerModel) => {
        return (
          <Button
            className="text-body"
            onClick={setFormItem.bind(null, customer)}
            variant="link"
          >
            <span className="material-icons material-icons-outlined fs-5 align-middle">
              edit_note
            </span>
          </Button>
        );
      },
    },
    {
      label: 'Excluir',
      field: 'delete',
      style: { width: '1px' },
      render: (customer: CustomerModel) => {
        return (
          <Button
            className="text-body"
            onClick={setDeletingModel.bind(null, customer)}
            variant="link"
          >
            <span className="material-icons material-icons-outlined fs-5 align-middle">
              delete
            </span>
          </Button>
        );
      },
    },
  ];

  function afterDelete() {
    setDeletingModel(false);
    fetchCollection();
  }

  function renderModalContent() {
    if (formItem === false) return null;

    return (
      <>
        <Modal.Header className="px-0">
          <Modal.Title as={'h3'}>
            {formItem.id ? formItem.name : <strong>Novo cliente</strong>}
          </Modal.Title>
        </Modal.Header>
        <CustomersForm
          inModal
          id={formItem.id}
          afterSave={() => {
            NotificationService.success(
              formItem.id ? 'Cliente alterado.' : 'Novo cliente criado.',
              'Sucesso'
            );
            setFormItem(false);
            fetchCollection();
          }}
          onCancel={setFormItem.bind(null, false)}
        />
      </>
    );
  }

  function renderDeleteModalContent() {
    if (!deletingModel) {
      return null;
    }

    return (
      <>
        <Modal.Body>Deseja excluir o cliente {deletingModel.name}?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button onClick={confirmDelete} variant="danger">
              Excluir
            </Button>
            <Button
              onClick={setDeletingModel.bind(null, false)}
              variant="white"
            >
              Fechar
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  }

  return (
    <div className="layout-wrapper layout-wrapper-list">
      <div className="mb-4">
        <ListTop
          q={searchParams.get('q') || ''}
          onSearch={onSearch}
          searchText="Buscar cliente"
          toggleFilters={toggleFilters.bind(null, !isFiltersVisible)}
          custom={
            <Button
              onClick={setFormItem.bind(
                null,
                CustomerService.getDefaultModel()
              )}
              variant="primary"
            >
              <span className="material-icons material-icons-outlined fs-5 align-middle me-2">
                person_add
              </span>
              <span className="align-middle">Novo cliente</span>
            </Button>
          }
        />
      </div>

      <div className="pb-3">
        <CustomersFilters
          isFiltersVisible={isFiltersVisible}
          linkFilter={linkFilter}
          // searchParams={searchParams}
        />
      </div>

      <div className="mb-2">
        <DynamicTable
          loading={status === 'pending' || deletingStatus === 'pending'}
          getRowKey={(customer) => customer.id}
          columns={columns}
          collection={collection}
          searchParams={searchParams}
          onSort={onSort}
        />
      </div>

      <Paginator
        currentPage={pagination.currentPage}
        offset={pagination.offset}
        total={pagination.total}
        onPaginate={onPaginate}
      />

      <Modal
        dialogClassName="mw-1100p"
        contentClassName="p-3"
        show={formItem !== false}
        onHide={setFormItem.bind(null, false)}
      >
        {renderModalContent()}
      </Modal>

      <Modal
        show={deletingModel !== false}
        onHide={setDeletingModel.bind(null, false)}
      >
        {renderDeleteModalContent()}
      </Modal>
    </div>
  );
}
